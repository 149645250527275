@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/functions';

$primary: #ebbc15;

$title-color: $grey-dark;
$title-weight: $weight-light;

$subtitle-color: $grey-dark;
$subtitle-weight: $weight-light;

$content-heading-color: $grey-darker;
$content-heading-weight: $weight-light;

$text: $grey-darker;

$fullhd: 1152px;

$family-sans-serif: 'Open Sans', BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';

@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/elements/image.sass';
@import '../node_modules/bulma/sass/elements/title.sass';

@import '../node_modules/bulma/sass/grid/columns.sass';

.row {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

html {
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(230, 184, 19, 0.47942927170868344) 44%, rgba(0, 212, 255, 0.5102415966386555) 100%);
}

.container {
    padding: 1rem;
}

#age {
    cursor: pointer;
}

.baby {
    transition: transform 0.5s;
    &:hover {
        transform: scale(1.05);
    }
}

.facts {
    list-style-type: square;
    list-style-position: outside;
    font-size: 1.5rem;
    margin-left: 1.5rem;
    font-weight: 100;
    li {
        padding: 0.4rem 0;
    }
}
